var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-wrap"},[_c('div',{staticClass:"form-content"},[_c('a-form',{staticClass:"login-form",attrs:{"id":"form-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('h3',{staticClass:"title"},[_vm._v("BI系统")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'passport',
            { rules: [{ required: true, message: '请输入账号!' }] },
          ]),expression:"[\n            'passport',\n            { rules: [{ required: true, message: '请输入账号!' }] },\n          ]"}],attrs:{"placeholder":"账号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'passwd',
            { rules: [{ required: true, message: '请输入密码!' }] },
          ]),expression:"[\n            'passwd',\n            { rules: [{ required: true, message: '请输入密码!' }] },\n          ]"}],attrs:{"type":"password","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v("登录")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }