// 登陆页面
<template>
  <div class="form-wrap">
    <div class="form-content">
      <a-form
        id="form-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <h3 class="title">BI系统</h3>
        <a-form-item>
          <a-input
            v-decorator="[
              'passport',
              { rules: [{ required: true, message: '请输入账号!' }] },
            ]"
            placeholder="账号"
          >
            <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-decorator="[
              'passwd',
              { rules: [{ required: true, message: '请输入密码!' }] },
            ]"
            type="password"
            placeholder="密码"
          >
            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit" class="login-form-button">登录</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { isLogin } from '../../server/login'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  methods: {
    ...mapActions(['loginAction']),
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          isLogin(values).then(res => {
            if (res.data.code === '0') {
              this.$message.success('登录成功！')
              // 存储登录信息到缓存里面
              sessionStorage.setItem('userInfo', JSON.stringify(res.data.data))
              this.loginAction({
                userInfo: res.data.data,
                isLogin: true
              })
              this.$router.push('/')
            } else {
              this.$message.error(res.data.message)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .form-wrap {
    min-height: 100%;
    width: 100%;
    background-color: #2d3a4b;
    overflow: hidden;
    .form-content {
      position: relative;
      width: 520px;
      max-width: 100%;
      padding: 160px 35px 0;
      margin: 0 auto;
      overflow: hidden;
      .title {
        font-size: 26px;
        color: #eee;
        margin: 0px auto 40px auto;
        text-align: center;
        font-weight: bold;
      }
      .login-form {
        .login-form-forgot {
          float: right;
        }
        .login-form-button {
          width: 100%;
        }
      }
    }
  }
</style>
